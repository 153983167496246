<template>
  <AppModal :id="id">
    <b-overlay :show="isLoading">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="user === undefined">
        <h4 class="alert-heading">
          {{ $t("no_data_found") || "no Data found" }}
        </h4>
        <div class="alert-body">
          No user found with this user id. Check
          <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
            User List
          </b-link>
          for other users.
        </div>
      </b-alert>

      <template v-if="user">
        <user-view-user-info-card :user-data="user" />
        <user-view-user-permissions-card :user-data="user" />
      </template>
    </b-overlay>
  </AppModal>
</template>

<script>
import { BAlert, BLink, BOverlay } from "bootstrap-vue";
import UserViewUserInfoCard from "../users-view/UserViewUserInfoCard.vue";
import UserViewUserPermissionsCard from "../users-view/UserViewUserPermissionsCard.vue";

export default {
  components: {
    BAlert,
    BLink,
    BOverlay,
    UserViewUserInfoCard,
    UserViewUserPermissionsCard,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // userData() {
    //   return this.$store.state.users.userData;
    // },
    isLoading() {
      return this.$store.state.users.isLoading.fetchingUserData;
    },
  },
  created() {
    this.$store.dispatch("users/fetchUserData", {
      uuid: this.user.uuid,
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}
</style>
