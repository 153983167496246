<template>
  <AppForm
    :id="id"
    ref="refFormObserver"
    :title="
      user
        ? $t('edit_form', { name: user.name }) || `Edit ${user.name}`
        : $t('add_new_user') || 'Add New User'
    "
    class="p-2"
    :is-loading="isLoading"
    :errors-bag="errorsBag"
    @submit.prevent="handleSubmit"
    @reset.prevent="resetForm"
    @hide="hide"
  >
    <AppInput
      id="full-name"
      v-model="form.name"
      :placeholder="$t('full_name') || 'Full Name'"
      type="text"
      required
      :label="$t('full_name') || 'Full Name'"
      rules="required"
      aria-autocomplete="none"
      autocomplete="off"
    />

    <!-- Email -->
    <AppInput
      id="email"
      v-model="form.email"
      placeholder="JohnDoe@gmail.com"
      type="email"
      required
      :label="$t('email') || 'Email'"
      rules="required|email"
      aria-autocomplete="none"
      autocomplete="off"
    />

    <!-- mobile -->
    <AppInput
      id="mobile"
      v-model="form.mobile"
      placeholder="+20 1234567890"
      type="tel"
      required
      :label="$t('mobile') || 'Mobile'"
      rules="required|min:11|max:13"
      aria-autocomplete="none"
      autocomplete="off"
    />

    <!-- Password -->
    <transition name="slide">
      <div v-if="user && !changeUserPassword">
        <AppButton
          variant="secondary"
          class="mb-2"
          type="button"
          @click="changeUserPasswordHandler"
        >
          {{ $t("change_password") || "Change Password" }}
        </AppButton>
      </div>
    </transition>

    <transition name="slide">
      <div v-if="!user || changeUserPassword">
        <AppInput
          id="password"
          v-model="form.password"
          placeholder="********"
          type="password"
          :required="!user || !user.password"
          :label="$t('password') || 'Password'"
          :rules="!user ? 'required|min:8' : ''"
          autocomplete="new-password"
        />
      </div>
    </transition>

    <AppSelect
      id="roles-selectbox"
      v-model="selectedRolesArr"
      :placeholder="$t('select_roles') || 'Select Roles'"
      :field-label="$t('select_roles') || 'Select Roles'"
      multiple
      :options="roles"
      search-store-action="roles/fetchAllData"
      required
    />

    <AppSelect
      id="actors-selectbox"
      v-model="selectedActorsArr"
      :placeholder="$t('select_actors') || 'Select Actors'"
      multiple
      :field-label="$t('select_actors') || 'Select Actors'"
      :options="actors"
      search-store-action="actors/fetchAllData"
      required
    />
  </AppForm>
</template>

<script>
import {
  AppInput,
  AppButton,
  AppForm,
  AppSelect,
} from "@/components/form/index";

export default {
  components: {
    AppInput,
    AppButton,
    AppForm,
    AppSelect,
  },
  props: {
    id: {
      type: String,
      default: "user-form",
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        mobile: "",
        password: "", // TODO : add ui Suggest password
        image: undefined,
        actors: [],
        roles: [],
      },
      changeUserPassword: false,
      selectedRolesArr: [],
      selectedActorsArr: [],
    };
  },
  computed: {
    roles() {
      return this.$store.state.roles.data;
    },
    actors() {
      return this.$store.state.actors.data;
    },
    isLoading() {
      return (
        this.$store.state.users.isLoading.creating ||
        this.$store.state.users.isLoading.updating
      );
    },
    selectedActors() {
      return this.user?.actors?.map((actor) => actor?.uuid) || [];
    },
    errorsBag() {
      return this.$store.state.users.errors;
    },
  },
  watch: {
    user: {
      handler(user) {
        if (user) {
          const userData = JSON.parse(JSON.stringify(this.user));
          this.form = {
            uuid: userData.uuid,
            name: userData.name,
            email: userData.email,
            mobile: userData.mobile,
            password: userData.password,
            image: userData.image,
            actors: userData.actors.map((actor) => actor.uuid),
            roles: userData.roles.map((role) => role.uuid),
          };
          this.selectedRolesArr = userData.roles.map((role) => ({
            label: role.name,
            value: role.uuid,
          }));
          this.selectedActorsArr = userData.actors.map((actor) => ({
            label: actor.name,
            value: actor.uuid,
          }));
        } else {
          this.changeUserPassword = false;
          this.resetForm();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    changeUserPasswordHandler() {
      this.changeUserPassword = !this.changeUserPassword;
    },
    handleSubmit(e) {
      const actionName = this.user ? "users/updateData" : "users/createData";

      delete this.form.image;

      this.form.actors = this.selectedActorsArr.map((actor) => actor.value);
      this.form.roles = this.selectedRolesArr.map((role) => role.value);

      this.$store
        .dispatch(actionName, JSON.parse(JSON.stringify(this.form)))
        .then(() => {
          this.hide();
        });
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        mobile: "",
        password: "",
        image: undefined,
        actors: [],
        roles: [],
      };
    },
    hide() {
      this.$emit("hide");
      this.$store.dispatch("users/resetModule");
      this.resetForm();
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
