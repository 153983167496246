<template>
  <AppModal :id="id" @hide="$emit('close')">
    <b-overlay :show="isLoading" class="p-1">
      <header class="mb-1 d-flex align-items-center justify-content-between">
        <h5 class="modal-title d-inline">
          {{ title || actionLabel }}
        </h5>
        <button type="button" class="close" @click="hideModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </header>

      <p>{{ message }}</p>

      <slot></slot>

      <section class="d-flex justify-content-end mt-2">
        <button
          type="button"
          class="btn btn-secondary mi-end-2"
          @click="hideModal"
        >
          {{ cancelLabel }}
        </button>
        <button type="button" :class="`btn btn-${variant}`" @click="deleteItem">
          {{ actionLabel }}
        </button>
      </section>
    </b-overlay>
  </AppModal>
</template>

<script>
import { BOverlay } from "bootstrap-vue";

export default {
  name: "DeleteWarning",
  components: {
    BOverlay,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "deleteWarningModal",
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: "Are you sure?",
    },
    actionLabel: {
      type: String,
      required: false,
      default: "Delete",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel",
    },
    variant: {
      type: String,
      required: false,
      default: "danger",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
  methods: {
    deleteItem() {
      this.$emit("delete", this.id);
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", this.id);
      this.$emit("close");
    },
    showModal() {
      this.$emit("open");
      this.$root.$emit("bv::show::modal", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
