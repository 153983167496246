<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>
        {{ $t("permissions") || "Permissions" }}
      </b-card-title>
      <b-card-sub-title>
        {{
          $t("permissions_according_to_roles") ||
            "Permission according to roles"
        }}
      </b-card-sub-title>
    </b-card-body>
    <b-list-group>
      <b-list-group-item
        v-for="(role, key) in userData.roles"
        :key="key"
        class="flex-column align-items-start rounded-0"
      >
        <h5 class="mb-1">
          {{ role.name }}
        </h5>
        <b-card-text class="mb-1">
          <b-badge
            v-for="(item, i) in role.permission_action"
            :key="i"
            class="mi-end-2 mb-end-2"
          >
            {{ item.permission_actions.permission.name }} (
            {{ item.permission_actions.action.name }} )
          </b-badge>
        </b-card-text>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BCardText,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      userPermissions: [],
    };
  },
};
</script>

<style>
</style>
