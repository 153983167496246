<template>
  <b-card no-body>
    <!-- v-if="userCan('role-access') || userCan('activation-toggle-access')" -->
    <b-card-header class="pb-50">
      <h5>
        {{ $t("filters") || "Filters" }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <AppSelect
            id="roles-select"
            v-model="roleFilter"
            :options="roles"
            class="w-100"
            :placeholder="$t('filter_by_role') || 'filter by Role'"
            :field-label="$t('role') || 'Role'"
            :label="$t('filter_by_role') || 'filter by Role'"
            search-store-action="roles/fetchAllData"
            @input="filterUsersHandler"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <AppSelect
            id="actors-select"
            v-model="actorFilter"
            :options="actors"
            class="w-100"
            :placeholder="$t('filter_by_actor') || 'filter by Actor'"
            :field-label="$t('actor') || 'Actor'"
            :label="$t('filter_by_actor') || 'filter by Actor'"
            search-store-action="actors/fetchAllData"
            @input="filterUsersHandler"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>
            {{ $t("status") || "Status" }}
          </label>
          <v-select
            id="isactive_checkbox"
            v-model="statusFilter"
            :dir="isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :placeholder="$t('all') || 'all'"
            @input="filterUsersHandler"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import userCan from "@/mixins/UserCan";
import { AppSelect } from "@/components/form/index";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormCheckbox,
    AppSelect,
  },
  data() {
    return {
      roleFilter: null,
      actorFilter: null,
      statusFilter: null,
      statusOptions: [
        {
          label: this.$t("all") || "All",
          value: null,
        },
        {
          label: this.$t("active") || "Active",
          value: 1,
        },
        {
          label: this.$t("inactive") || "Inactive",
          value: 0,
        },
      ],
    };
  },
  computed: {
    roles() {
      return this.$store.state.roles.data;
    },
    actors() {
      return this.$store.state.actors.data;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  created() {
    // this.$store.dispatch("roles/fetchRoles");
    // this.$store.dispatch("actors/fetchActors");
  },
  methods: {
    userCan,
    filterUsersHandler() {
      // all filters
      const filters = {
        role: this.roleFilter ? this.roleFilter.value : undefined,
        actor: this.actorFilter ? this.actorFilter.value : undefined,
        ...(this.statusFilter &&
        Object.keys(this.statusFilter).includes("value")
          ? { is_active: this.statusFilter.value }
          : {}),
      };

      this.$emit("filterUsers", filters);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
