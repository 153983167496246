<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
            :src="userData.image"
            :text="avatarText(userData.name)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="">
              <h4 class="mb-05">
                {{ userData.name }}
              </h4>
              <p class="card-text mb-05">
                {{ userData.email }}
              </p>
              <p class="card-text mb-0">
                {{ userData.mobile }}
              </p>
            </div>

            <!-- TODO : add edit and delete actions in this page  -->
            <!-- <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button variant="outline-danger" class="ml-1">
                Delete
              </b-button>
            </div> -->
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">
                {{ $t("status") || "Status" }}
              </span>
            </th>
            <td class="pb-50 text-capitalize">
              {{
                userData.is_active
                  ? $t("active") || "Active"
                  : $t("inactive") || "Inactive"
              }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
 BCard, BButton, BAvatar, BRow, BCol
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../useUsersList";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {
  //     users: this.userData,
  //   };
  // },
  // watch: {
  //   userData: {
  //     handler(val) {
  //       this.user = val;
  //     },
  //     deep: true,
  //   },
  // },
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.mb-05 {
  margin-bottom: 0.5rem;
}

// p {
//   line-height: 1rem;
// }
</style>
